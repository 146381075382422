<template>
  <b-row v-if="renderPage">
    <b-container>
      <b-overlay
        :show="showLoading"
        rounded="lg"
        :opacity="1"
      >
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <strong>{{ loadingMessage }}, Aguarde...</strong>
            <b-icon
              icon="stopwatch"
              font-scale="3"
              animation="cylon"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
            <b-spinner
              type="grow"
              variant="primary"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
          </div>
        </template>
        <transition name="slide-fade">
          <b-card title="Editar Usuário Ecommerce">
            <validation-observer
              #default="{invalid}"
              ref="simpleRules"
            >
              <b-form
                class="needs-validation"
                @submit.prevent
              >
                <b-card-body>
                  <table class="table table-striped table-hover table-bordered">
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Nome:
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ userDetails.name }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          CNPJ:
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ customerTaxIdMasked }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Número do EC:
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ userDetails.veripag_id }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Grupo:
                        </td>
                        <td>
                          <b-form-select
                            v-model="customerGroup"
                            :options="groupsOptions"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Email:
                        </td>
                        <td>
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="required|email"
                          >
                            <b-form-input
                              v-model="userDetails.email"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider></td>
                      </tr>
                      <tr v-if="userDetails.bankslip_limit">
                        <td class="pr-1">
                          Limite de Boletos:
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ userDetails.bankslip_limit }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="demo-inline-spacing mb-1">
                    <div>
                      <b-card-text class="mb-0">
                        Boleto
                      </b-card-text>
                      <b-form-checkbox
                        v-model="checkedBankslip"
                        checked="true"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-card-text class="mb-0">
                        Venda Direta
                      </b-card-text>
                      <b-form-checkbox
                        v-model="checkedDirectSale"
                        checked="true"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </div>

                    <div>
                      <b-card-text class="mb-0">
                        Recorrência
                      </b-card-text>
                      <b-form-checkbox
                        v-model="checkedRecurrence"
                        checked="true"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-card-text class="mb-0">
                        Integração
                      </b-card-text>
                      <b-form-checkbox
                        v-model="checkedIntegration"
                        checked="true"
                        name="check-button"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                  <b-row v-if="checkedBankslip">
                    <b-col
                      md="6"
                      sm="12"
                      lg="6"
                    >
                      <b-form-group
                        label="Número de boletos"
                        label-for="v-qtd-bankslip"
                      >
                        <b-form-input
                          id="v-qtd-bankslip"
                          v-model="bankSlipAmount"
                          type="number"
                          :min="1"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      md="6"
                      class="container-btn"
                    >
                      <b-button
                        variant="primary"
                        :disabled="invalid"
                        @click="showConfirm"
                      >Salvar</b-button>
                      <b-button
                        variant="primary"
                        :to="{ name: 'e-commerce' }"
                      >Voltar</b-button>

                    </b-col>
                  </b-row>
                </b-card-body>
              </b-form>
            </validation-observer>
          </b-card>
        </transition>
      </b-overlay>
    </b-container>
  </b-row>
</template>
<script>
import {
    BContainer,
    BRow,
    BFormGroup,
    BFormInput,
    BCard,
    BButton,
    BCardBody,
    BFormSelect,
    BFormCheckbox,
    BCardText,
    BCol,
    BSpinner,
    BOverlay,
    BIcon,
    BForm,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { updatePermission, findUser, updateCustomer } from '@/service/user/userEcommerce';

import GroupService from '@/service/merchant/mercury/group';

import { getGroupsOptions } from './GetGroupsOptions';

export default {
    components: {
        BContainer,
        BRow,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BCard,
        BCardBody,
        BButton,
        BFormCheckbox,
        BCardText,
        BCol,
        BSpinner,
        BOverlay,
        BIcon,
        BForm,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userDetails: {},
            checkedDirectSale: false,
            checkedRecurrence: false,
            checkedBankslip: false,
            customerGroup: null,
            checkedIntegration: false,
            bankSlipAmount: 0,
            permission: [],
            showLoading: false,
            loadingMessage: ' ',
            renderPage: false,
            required,
            currentEmail: '',
            merchantId: null,
        };
    },

    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
    computed: {
        customerTaxIdMasked() {
            return this.userDetails.tax_id?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        },

        groupsOptions() {
            return getGroupsOptions(this.groups);
        },
    },
    async mounted() {
        await this.checkSaleEcommerce();
        const { data } = await GroupService.getAllGroups();
        this.groups = data.data;
        this.renderPage = true;
    },
    methods: {

        async checkSaleEcommerce() {
            const { id } = this.$route.params;

            const { data, status } = await findUser(id);
            const { bankslip_limit, userPermission } = data;

            if (status !== 200) {
                this.$router.push('/error-404');
                return;
            }
            this.bankSlipAmount = bankslip_limit;
            this.userDetails = data;

            this.currentEmail = data.email;

            this.merchantId = data.veripag_id;

            const userPermissions = userPermission.map(user => user.permission_id);

            this.customerGroup = data.group?.id || 1;
            this.checkedBankslip = userPermissions.some(permission => permission === 4);
            this.checkedDirectSale = userPermissions.some(permission => permission === 3);
            this.checkedRecurrence = userPermissions.some(permission => permission === 5);
            this.checkedIntegration = userPermissions.some(permission => permission === 56);
        },

        async updatePermission() {
            this.loadingMessage = 'Atualizando os dados..';
            this.showLoading = true;

            const userEcommercePermission = new Set();

            if (this.checkedBankslip) {
                userEcommercePermission.add(11);
                userEcommercePermission.add(4);
            } else {
                this.bankSlipAmount = 0;
                userEcommercePermission.delete(11);
                userEcommercePermission.delete(4);
            }

            if (this.checkedDirectSale) {
                userEcommercePermission.add(3);
                userEcommercePermission.add(9);
            } else {
                userEcommercePermission.delete(3);
                userEcommercePermission.delete(9);
            }

            if (this.checkedRecurrence) {
                userEcommercePermission.add(10);
                userEcommercePermission.add(5);
            } else {
                userEcommercePermission.delete(10);
                userEcommercePermission.delete(5);
            }

            if (this.checkedIntegration) {
                userEcommercePermission.add(56);
            } else {
                userEcommercePermission.delete(56);
            }

            const permission = [...userEcommercePermission];
            const { id } = this.$route.params;

            const response = await updatePermission(permission, id);

            const { email } = this.userDetails;
            const isEmailUpdated = email !== this.currentEmail;
            await updateCustomer({
                new_email: isEmailUpdated ? email : undefined, current_email: isEmailUpdated ? this.currentEmail : undefined, group_id: this.customerGroup, merchant_id: this.merchantId,
            });
            const { status } = response;
            if (status === 200) { this.success(); } else { this.error('Falha na atualização'); }

            this.showLoading = false;
        },
        error(loadingMessage) {
            this.$swal({
                icon: 'error',
                text: loadingMessage,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },
        success() {
            this.$swal({
                icon: 'success',
                html: '<span>Usuário Atualizado com sucesso! </span> ',
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },
        showConfirm() {
            this.$bvModal
                .msgBoxConfirm('Deseja Atualizar os Dados?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.updatePermission();
                    }
                });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/new-client.scss";

.container-btn{
  .btn {
    width: 12rem !important;
    margin-left: 1rem;
  }
  .btn:first-child {
    margin-left: 0;
  }
}

@media (max-width: 868px){
  .container-btn{
    .btn {
      width: 100% !important;
      margin-left: 0;
      margin-top: 0.7rem;
    }

    .btn:first-child {
    margin-top: 0;
    }
  }
}
</style>
